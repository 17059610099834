@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/mixins" as *;

mat-slide-toggle.mat-slide-toggle {
    height: 22px;

    .mat-slide-toggle-bar {
        height: 22px;
        background-color: $color-gray-100;
        border-radius: 11px;
        margin-inline: 4px;
        width: 44px;

        &:focus-within {
            @include focus-outline(2px);
        }
    }

    .mat-slide-toggle-label {
        align-items: flex-start;
        justify-content: var(--mat-slide-toggle-justify-content);
    }

    .mat-slide-toggle-thumb-container {
        width: 18px;
        height: 18px;
        top: 2px;
        left: 2px;

        .mat-slide-toggle-thumb {
            height: 18px;
            width: 18px;
            box-shadow: none;
            background-color: $color-white;
        }
    }

    &.mat-checked .mat-slide-toggle-bar {
        background-color: $color-secondary-700;

        .mat-slide-toggle-thumb {
            transform: translate3d(6px, 0, 0);
        }
    }

    .mat-slide-toggle-ripple {
        display: none;
    }

    &.spread .mat-slide-toggle-label {
        justify-content: space-between;
    }

    &.allow-wrap {
        height: auto;

        .mat-slide-toggle-content {
            white-space: normal;
        }
    }

    &.toggle-bar-center .mat-slide-toggle-bar {
        align-self: center;
    }
}
