@use "application-preview";

ac-map {
    .cesium-widget-credits {
        padding-left: 8px;

        .cesium-credit-logoContainer a[href="https://cesium.com/"]
        {
            display: none;
        }
    }

    canvas {
        border-radius: 8px;
    }
}

/* Remove arrows/spinners for number input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* End remove arrows/spinners for number input */
