@use "libs/shared/styles/style-guide-colors" as *;

$form-card-width: 420px;

.application-preview {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .card-box {
        width: $form-card-width;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
    }

    dss-shared-lib-map-container {
        height: 100%;
        width: calc(100% - #{$form-card-width} - var(--grid-gutter-width));
    }

    .overflow-container {
        flex: 1 1 auto;
        overflow: auto;
        padding: 0 16px 24px 24px;
        margin-top: 16px;
    }

    .actions {
        flex: 0 0 min-content;
        padding: 16px 16px 16px 24px;
        border-top: 1px solid $color-gray-50;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        box-shadow: 0px -8px 24px rgba($color-gray-500, 0.08);

        .full-width-button {
            grid-column: 1 / span 2;
        }
    }
}
